import React from "react";
import "./App.css";
import Chat from "./Components/Chat/Chat";
import ChatService from "./Services/ChatService";
import UserService from "./Services/UserService";

function App() {
  const userService = UserService();
  const chatService = ChatService({ userService });

  return (
    <div className="app">
      <div className="app-main">
        {/* <div className="ad-box">Ad Will be added here</div> */}
        <Chat userService={userService} chatService={chatService}></Chat>
      </div>
    </div>
  );
}

export default App;
