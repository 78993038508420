import React, { useEffect, useRef, useState } from "react";
import { ChatServiceType, Message } from "Services/ChatService";
import { ChatStatus, UserServiceType } from "Services/UserService";
import "styles/Chat.css";
import ChatBar from "./ChatBar";

const ChatWindow = ({ chatService, userService }: ChatWindowProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [disabled, setDisabled] = useState(true);
  const [userId, setUserId] = useState<string>();

  const chatWindowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMessages(chatService.getMessages() || []);
  }, [chatService.getMessages]);

  useEffect(() => {
    chatWindowRef.current?.scrollTo(0, chatWindowRef.current.scrollHeight);
  }, [messages]);

  useEffect(() => {
    setUserId(userService.getUserId());
  }, [userService.getUserId]);

  useEffect(() => {
    const chatStatus = userService.getChatStatus();
    if (
      chatStatus == ChatStatus.InGroupChat ||
      chatStatus == ChatStatus.InOneToOneChat
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [userService.getChatStatus]);

  return (
    <div className="chat-window" ref={chatWindowRef}>
      {messages.map((message: Message, index: number) => {
        const preSource = messages[index - 1]?.source;

        return (
          <div key={index} className="chat-message-wrapper">
            {renderChatMessage(message, userId || "", preSource)}
          </div>
        );
      })}
    </div>
  );
};

const renderChatMessage = (
  message: Message,
  userId: string,
  preSource: string
) => {
  if (message.source == "system") {
    return <div className="system-message">{message.content}</div>;
  } else if (userId == message.source) {
    return (
      <div className="my-message-wrapper">
        {preSource == null || message.source != preSource ? (
          <div className="message-source">You</div>
        ) : (
          ""
        )}
        <div className="my-message chat-message">{message.content}</div>
      </div>
    );
  } else {
    return (
      <div className="other-message-wrapper">
        {preSource == null || message.source != preSource ? (
          <div className="message-source">Other</div>
        ) : (
          ""
        )}
        <div className="other-message chat-message">{message.content}</div>
      </div>
    );
  }
};

interface ChatWindowProps {
  chatService: ChatServiceType;
  userService: UserServiceType;
}
export default ChatWindow;
