import React from "react";
const ChatMenu = ({ isOpen, setIsMenuOpen, chatService }: any) => {
  if (!isOpen) {
    return null;
  }
  const clearMessages = () => {
    chatService.setMessages([]);
    setIsMenuOpen(false);
  };

  return (
    <div className="chat-menu">
      <button className="chat-menu-button" onClick={clearMessages}>
        Clear Chat
      </button>
    </div>
  );
};

export default ChatMenu;
