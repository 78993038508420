import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "Components/Shared/ConfirmationModal";
import { ChatStatus } from "Services/UserService";
import React, { useEffect, useState } from "react";
import ChatMenu from "./ChatMenu";

const ChatBar = ({ chatService, userService }: any) => {
  const [disabled, setDisabled] = useState(false);
  const [isOpenExitModal, setIsOpenExitModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const chatStatus = userService.getChatStatus();
    if (
      chatStatus == ChatStatus.InGroupChat ||
      chatStatus == ChatStatus.InOneToOneChat
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [userService.getChatStatus]);
  const openExitModal = () => {
    setModalMessage("Are you sure you want to exit?");
    setIsOpenExitModal(true);
  };

  const exitChat = async () => {
    await chatService.leaveOneToOneChat();
    setIsOpenExitModal(false);
  };

  return (
    <div className="chat-bar">
      <div className="chat-bar-left">
        <button
          className="menu-button"
          onClick={() => {
            console.log("clicked");
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          <FontAwesomeIcon icon={faBars} size="1x" />
        </button>
      </div>
      <div className="chat-menu-wrapper">
        <ChatMenu isOpen={isMenuOpen} 
        setIsMenuOpen={setIsMenuOpen}
        chatService={chatService} />
      </div>

      <div className="chat-bar-right">
        <button
          className="exit-button"
          onClick={openExitModal}
          disabled={disabled}
        >
          <FontAwesomeIcon icon={faXmark} size="1x" />
        </button>
      </div>
      <ConfirmationModal
        isOpen={isOpenExitModal}
        close={() => setIsOpenExitModal(false)}
        confirm={exitChat}
        message={modalMessage}
      />
    </div>
  );
};

export default ChatBar;
