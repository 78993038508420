import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { ChatServiceType } from "Services/ChatService";
import { ChatStatus, UserServiceType } from "Services/UserService";
import "styles/Chat.css";

const ChatPrompt = ({ userService, chatService }: ChatPromptProps) => {
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const chatStatus = userService.getChatStatus();
    if (
      chatStatus == ChatStatus.InGroupChat ||
      chatStatus == ChatStatus.InOneToOneChat
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [userService.getChatStatus]);

  const messageInputRef = useRef<HTMLInputElement>(null);

  const sendMessage = () => {
    if (message.trim() === "") {
      return;
    }

    if (userService.getChatStatus() == ChatStatus.InGroupChat) {
      chatService.sendMessageToChatGroup(message);
    } else if (userService.getChatStatus() == ChatStatus.InOneToOneChat) {
      chatService.sendMessageToOneToOneChat(message);
    }

    if (messageInputRef.current != null) {
      messageInputRef.current.value = "";
    }

    setMessage("");
  };

  const keyDownMessageInput = (e: any) => {
    if (e.key != "Enter") {
      return;
    }

    sendMessage();
  };

  const changeMessageInput = (e: any) => {
    // input limit: 250 characters
    if (e.target.value.length > 250) {
      e.target.value = e.target.value.slice(0, 250);
    }

    setMessage(e.target.value);
  };

  return (
    <div className="chat-prompt">
      <input
        className="chat-prompt-input"
        type="text"
        ref={messageInputRef}
        onChange={changeMessageInput}
        onKeyDown={keyDownMessageInput}
        disabled={disabled}
      />
      <FontAwesomeIcon
        icon={faPaperPlane}
        className={`chat-prompt-icon ${disabled ? "chat-prompt-icon-disabled" : ""}`}
        onClick={sendMessage}
      />
    </div>
  );
};

interface ChatPromptProps {
  chatService: ChatServiceType;
  userService: UserServiceType;
}

export default ChatPrompt;
