import React from "react";
import "styles/Modal.css";

const ConfirmationModal = ({ isOpen, close, confirm, message }: any) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="backdrop">
      <div className="overlay">
        <div className="modal-message-wrapper">{message}</div>
        <div className="button-wrapper">
          <button className="cancel-button" onClick={close}>
            Cancel
          </button>
          <button className="confirm-button" onClick={confirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
