import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { ChatStatus, UserServiceType } from "./UserService";

const ChatService = ({ userService }: ChatServiceProps) => {
  //const endpoint = "http://localhost:5189/chatHub";
  const endpoint = "https://randimate-api.azurewebsites.net/chatHub";

  const [messages, setMessages] = useState<Message[]>([]);
  const [chatRoomList, setChatRoomList] = useState<string[]>();
  const [userList, setUserList] = useState<string[]>();
  const [connection, setConnection] = useState<HubConnection>();

  useEffect(() => {
    const initialConnection = new signalR.HubConnectionBuilder()
      .withUrl(endpoint, {
        //transport: signalR.HttpTransportType.WebSockets,
        //skipNegotiation: true,
      })
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    setConnection(initialConnection);
    const initialMessages = [new Message("Welcome to Randi Chat!")];
    setMessages(initialMessages);

    return () => {
      connection?.stop();
    };
  }, []);

  useEffect(() => {
    receiveMessage();
    receiveChatRoomList();
    receiveUserList();
    receiveEvent();
  }, [connection]);

  const getMessages = () => {
    return messages;
  };

  const getChatRoomList = () => {
    return chatRoomList;
  };

  const getUserList = () => {
    return userList;
  };

  const createChatRoom = async (chatRoomName?: string) => {
    return await connection?.invoke("CreateChatRoom", chatRoomName);
  };

  const joinGroupChat = async (chatRoomId: string) => {
    await connection?.invoke("JoinGroupChat", chatRoomId, null);
    userService.setJoinedChatRoomId(chatRoomId);
    userService.setChatStatus(ChatStatus.InGroupChat);

    //    setMessages([...messages, `You have joined chat room ${chatRoomId}`]);
  };

  const leaveGroupChat = async () => {
    await connection?.invoke("LeaveGroupChat");

    userService.setChatStatus(ChatStatus.Available);

    //    setMessages([...messages, `You have left chat room`]);
  };

  const joinOneToOneChat = async () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      new Message("Joining one-to-one chat..."),
    ]);
    await connection?.invoke("JoinOneToOneChat");
    userService.setChatStatus(ChatStatus.InOneToOneChat);
  };

  const leaveOneToOneChat = async () => {
    await connection?.invoke("LeaveOneToOneChat");
    userService.setChatStatus(ChatStatus.Available);
  };

  const receiveMessage = () => {
    connection?.on("ReceiveMessage", (message: Message) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        new Message(message.content, message.source),
      ]);
    });
  };

  const receiveEvent = () => {
    connection?.on("ReceiveEvent", (command) => {
      if (command == "leave") {
        userService.setChatStatus(ChatStatus.Available);
      }
    });
  };

  const receiveChatRoomList = () => {
    connection?.on("ReceiveChatRoomList", (chatRooms) => {
      setChatRoomList(chatRooms);
    });
  };

  const receiveUserList = () => {
    connection?.on("ReceiveUserList", (users) => {
      setUserList(users);
    });
  };

  const sendMessageToChatGroup = async (message: string) => {
    await connection?.invoke("SendMessageToChatGroup", message);
  };

  const sendMessageToOneToOneChat = async (message: string) => {
    await connection?.invoke("SendMessageToOneToOneChat", message);
  };

  const connect = async (username?: string) => {
    await connection?.start();
    userService.setIsConnected(true);
    userService.setUserId(connection?.connectionId || "");
    console.log("connection", connection);
  };

  const disconnect = async () => {
    await connection?.stop();
    userService.setIsConnected(false);
  };

  const start = async () => {
    userService.setChatStatus(ChatStatus.Waiting);

    try {
      if (connection?.state != signalR.HubConnectionState.Connected) {
        await connect();
      }

      await joinOneToOneChat();
    } catch (err) {
      console.log("err", err);
      setMessages([...messages, new Message("Failed to connect to server.")]);

      userService.setChatStatus(ChatStatus.Available);
    }
  };

  return {
    connect,
    disconnect,
    getMessages,
    setMessages,
    createChatRoom,
    getChatRoomList,
    getUserList,
    joinGroupChat,
    leaveGroupChat,
    leaveOneToOneChat,
    sendMessageToChatGroup,
    sendMessageToOneToOneChat,
    start,
  };
};

interface ChatServiceProps {
  userService: UserServiceType;
}

class Message {
  content: string = "";
  source: string = "system";

  constructor(content: string, source: string = "system") {
    this.content = content;
    this.source = source;
  }
}

type ChatServiceType = ReturnType<typeof ChatService>;

export default ChatService;
export type { ChatServiceType, Message };
