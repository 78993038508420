import React, { useEffect, useState } from "react";
import ChatPrompt from "Components/Chat/ChatPrompt";
import ChatWindow from "Components/Chat/ChatWindow";
import { ChatServiceType } from "Services/ChatService";
import { ChatStatus, UserServiceType } from "Services/UserService";
import "styles/Chat.css";
import ChatBar from "./ChatBar";

const Chat = ({ chatService, userService }: ChatProps) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const chatStatus = userService.getChatStatus();
    if (chatStatus != ChatStatus.Available) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [userService.getChatStatus]);

  const clickStartButton = () => {
    console.log("click");
    chatService.start();
  };

  return (
    <div className="chat-layout">
      <ChatBar chatService={chatService} userService={userService}></ChatBar>
      <ChatWindow
        chatService={chatService}
        userService={userService}
      ></ChatWindow>
      <ChatPrompt
        chatService={chatService}
        userService={userService}
      ></ChatPrompt>
      <div className="chat-start-button-wrapper">
        <button
          className="chat-start-button"
          onClick={clickStartButton}
          disabled={disabled}
        >
          Start Chat
        </button>
      </div>
    </div>
  );
};

interface ChatProps {
  chatService: ChatServiceType;
  userService: UserServiceType;
}

export default Chat;
export type { ChatProps };
