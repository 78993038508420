import { useState } from "react";

const UserService = () => {
  const [userId, setUserId] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [joinedChatRoomId, setJoinedChatRoomId] = useState<string>();
  const [chatStatus, setChatStatus] = useState(ChatStatus.Available);

  const getUserId = () => {
    return userId;
  };

  const getIsConnected = () => {
    return isConnected;
  }

  const getJoinedChatRoomId = () => {
    return joinedChatRoomId;
  };

  const getChatStatus = () => {
    return chatStatus;
  }

  return {
    getUserId,
    setUserId,
    getIsConnected,
    setIsConnected,
    getJoinedChatRoomId,
    setJoinedChatRoomId,
    getChatStatus,
    setChatStatus
  };
};

export enum ChatStatus {
  Available,
  InGroupChat,
  InOneToOneChat,
  Waiting
}

type UserServiceType = ReturnType<typeof UserService>;

export default UserService;
export type { UserServiceType };